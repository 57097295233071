.buyBox {
    padding: 17px 16px;
    width: 100%;
}

.buyBox .buyInfo {
    width: 100%;
}

.buyBox input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff;
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #101114;
}

/* .buyBox .ant-checkbox-inner {
    width: 24px;
    height: 24px;
}

.buyBox .ant-checkbox {
    font-size: 16px;
} */

.buyBox .gasInfoCard {
    border-radius: 8px;
    border: 1px solid #2B3139;
    background: rgba(16, 17, 20, 0.00);
    height: 149px;
    padding: 16px 14px 16px 21px;
    width: 100%;
    margin-bottom: 32px;
}

.buyBox label {
    width: 100%;
}

.buyBox .checkApprove label {
    width: auto;
}

.buyBox label {
    width: 100%;
}

.buyBox label::after {
    display: none;
}