.layoutsBox .buyItemInfo {
    height: 72px;
    width: 100%;
    padding: 11px 15px;
    background: #FDD535;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    transition: transform 0.25s ease-in-out 0s;
    transform: translateY(0);
    z-index: 2;
}

.layoutsBox .buyItemInfo.hidden {
  transform: translateY(300%);
}