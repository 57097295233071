.marketPlaceTableListBpx .ant-table.ant-table-small {
    border: none;
}

.marketPlaceTableListBpx .ant-table.ant-table-small .ant-table-tbody > tr > td {
    border: none;
}

.marketPlaceTableListBpx .ant-table-thead .ant-table-cell {
    background-color: transparent !important;
}

.marketPlaceTableListBpx .ant-table-thead >tr>th {
    border: none !important;
}

.marketPlaceTableListBpx .ant-table-thead >tr>th::before {
    height: 0px !important;
}

.marketPlaceTableListBpx .ant-table-thead .ant-table-cell .ant-table-filter-column {
    justify-content: flex-start;
}

.marketPlaceTableListBpx .ant-table-thead .ant-table-cell .ant-table-filter-column .ant-table-column-title {
    flex: unset;
}

.marketPlaceTableListBpx .ant-table-thead .ant-table-cell .ant-table-column-sorters {
    justify-content: flex-start;
}

.marketPlaceTableListBpx .ant-table-thead .ant-table-cell .ant-table-column-sorters .ant-table-column-title {
    flex: unset;
}

@media (max-width: 576px) {
    .marketPlaceTableListBpx .ant-table-thead .ant-table-cell {
        font-size: 13px !important;
    }
    .marketPlaceTableListBpx .ant-table-thead .ant-table-cell .ant-table-column-title {
        font-size: 13px;
    }
}