.sweepModalBox .gasCard {
    height: 85px;
    width: 164px;
    border-radius: 6px;
    border: 1px solid #1C2025;
    background: #1C2025;
    cursor: pointer;
}

.sweepModalBox .gasCard:hover {
    border: 1px solid #5D532C;
    background: #181A1F;
    box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
}

.sweepModalBox .gasCard.check {
    border: 1px solid #5D532C;
    background: #181A1F;
    box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
}

.sweepModalBox .gasInfoCard {
    border-radius: 8px;
    border: 1px solid #2B3139;
    background: #181A1F;
    height: 90px;
    padding: 27px 52px;
    width: calc(100% - 104px);
}

.sweepModalBox .feeInput {
    width: 48px;
    height: 27px;
    text-align: end;
    padding-left: 4px;
    padding-right: 6px;
    font-size: 17px;
}

.sweepModalBox .ant-slider-handle::after {
    background-color: #FDD535;
    box-shadow: 0 0 0 2px #FDD535 !important;
}

.sweepModalBox .broomList {
    border-radius: 8px;
    background: #101114;
    max-height: 120px;
    padding: 20px;
    width: calc(100% - 40px);
    overflow-y: auto;
}

.sweepModalBox .broomListBox {
    overflow-y: auto;
}

@media (max-width: 576px) {
    .sweepModalBox .gasCard {
        height: 82px;
        width: calc(33.3% - 35px);
    }
    .sweepModalBox .gasInfoCard {
        border-radius: 4px;
        height: 112px;
        padding: 9px 8px;
        width: calc(100% - 16px);
    }
    .sweepModalBox .broomList {
        border-radius: 4px;
        background: #101114;
        height: 99px;
        padding: 8px;
        width: calc(100% - 16px);
        overflow: hidden;
    }
}