.tradeResultModalBox .gasCard {
    height: 85px;
    width: 164px;
    border-radius: 6px;
    border: 1px solid #1C2025;
    background: #1C2025;
    cursor: pointer;
}

.tradeResultModalBox .gasCard:hover {
    border: 1px solid #5D532C;
    background: #181A1F;
    box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
}

.tradeResultModalBox .gasInfoCard {
    border-radius: 8px;
    border: 1px solid #2B3139;
    background: #181A1F;
    height: 144px;
    padding: 27px 52px;
    width: calc(100% - 104px);
}

.tradeResultModalBox .ant-slider-handle::after {
    background-color: #FDD535;
    box-shadow: 0 0 0 2px #FDD535 !important;
}

.tradeResultModalBox .broomList {
    border-radius: 8px;
    background: #101114;
    max-height: 122px;
    padding: 20px;
    width: calc(100% - 40px);
    overflow: hidden;
}

.tradeResultModalBox .broomListBox {
    overflow-y: auto;
}

@media (max-width: 576px) {
    .tradeResultModalBox .gasCard {
        height: 82px;
        width: calc(33.3% - 35px);
    }
    .tradeResultModalBox .gasInfoCard {
        border-radius: 4px;
        height: 112px;
        padding: 9px 8px;
        width: calc(100% - 16px);
    }
    .tradeResultModalBox .broomList {
        border-radius: 4px;
        background: #101114;
        max-height: 99px;
        padding: 8px;
        width: calc(100% - 16px);
        overflow: hidden;
    }
}