.sweepBox .gasCard {
    height: 85px;
    width: 164px;
    border-radius: 6px;
    border: 1px solid #1C2025;
    background: #1C2025;
    cursor: pointer;
}

.sweepBox {
    padding: 30px 16px;
}

.sweepBox .gasCard:hover {
    border: 1px solid #5D532C;
    background: #181A1F;
    box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
}

.sweepBox .gasCard.check {
    border: 1px solid #5D532C;
    background: #181A1F;
    box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
}

.sweepBox .feeInput {
    width: 30px;
    height: 18px;
    text-align: end;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 8px;
}

.sweepBox .gasInfoCard {
    border-radius: 8px;
    border: 1px solid #2B3139;
    background: #181A1F;
    height: 144px;
    padding: 27px 52px;
    width: 100%;
}

.sweepBox .ant-slider-handle::after {
    background-color: #FDD535;
    box-shadow: 0 0 0 2px #FDD535 !important;
}

/* .sweepBox .broomList {
    border-radius: 8px;
    background: #101114;
    max-height: 122px;
    padding: 20px;
    width: 100%;
    overflow: hidden;
} */

.sweepBox .broomListBox {
    overflow-y: auto;
}

@media (max-width: 576px) {
    .sweepBox .gasCard {
        height: 82px;
        width: calc(33.3% - 35px);
    }
    .sweepBox .gasInfoCard {
        border-radius: 4px;
        height: 80px;
        padding: 9px 8px;
        width: 100%;
    }
    .sweepBox .broomList {
        border-radius: 4px;
        background: #101114;
        max-height: 108px;
        padding: 8px;
        width: 100%;
        overflow-y: auto;
    }
}