.sellModalBox {
    padding: 17px 16px 0;
    width: calc(100% - 32px);
}

.sellModalBox .sellModalInfo {
    border-top: 0.5px solid #313131;
    width: 100%;
    padding-top: 17px;
}

.sellModalBox input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff;
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #101114;
}

.sellModalBox .ant-checkbox-inner {
    width: 24px;
    height: 24px;

    &::after {
        width: 8px;
        height: 12px;
    }
}

.sellModalBox .ant-checkbox {
    font-size: 24px;
}

.sellModalBox .ant-slider-handle::after {
    background-color: #FDD535;
    box-shadow: 0 0 0 2px #FDD535 !important;
}

.sellModalBox .gasInfoCard {
    border-radius: 8px;
    border: 1px solid #2B3139;
    background: rgba(16, 17, 20, 0.00);
    height: 120px;
    padding: 16px 14px 16px 21px;
    width: calc(100% - 35px);
    margin-bottom: 32px;
}

.sellModalBox label {
    width: 100%;
}

.sellModalBox .checkApprove label {
    width: auto;
}

.sellModalBox label {
    width: 100%;
}

.sellModalBox label::after {
    display: none;
}

.sellModalBox .broomList {
    border-radius: 8px;
    background: #101114;
    max-height: 122px;
    padding: 20px;
    width: calc(100% - 40px);
    overflow: hidden;
}

.sellModalBox .broomListBox {
    overflow-y: auto;
}

.sellModalBox .tag {
    color: #d89614;
    background: #2b2111;
    border-color: #594214;
    padding: 5px 4px;
    font-size: 14px;
    line-height: 12px;
    height: 12px;
    border-radius: 2px;
}

@media (max-width: 576px) {
    .sellModalBox {
        padding: 16px;
    }
    .smSellModal {
        width: 100vw !important;
        max-width: 100vw !important;
        top: 0;
        padding: 0 !important;
        margin: 0 !important;
    }
    
    .smSellModal .ant-modal-content {
        height: calc(100vh);
        overflow-y: auto;
        border-radius: 0;
        padding: 0;
    }

    .sellModalBox .sellModalInfo {
        border-top: unset;
        padding-top: 0px;
    }

    .sellModalBox .broomList {
        border-radius: 8px;
        background: #101114;
        max-height: 93px;
        padding: 10px;
        width: auto;
        overflow: hidden;
    }
    
    .sellModalBox .broomListBox {
        overflow-y: auto;
    }
    
    .sellModalBox .tag {
        color: #d89614;
        background: #2b2111;
        border-color: #594214;
        padding: 0 4px;
        font-size: 9px;
        line-height: 12px;
        height: 12px;
        border-radius: 2px;
    }

    .sellModalBox .gasInfoCard {
        border-radius: 8px;
        border: 1px solid #2B3139;
        background: rgba(16, 17, 20, 0.00);
        height: 88px;
        padding: 16px 14px 16px 21px;
        width: auto;
        margin-bottom: 32px;
    }
}