.scroll-header {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  transition: transform 0.2s ease-in-out 0s;
  transform: translateY(0);
  z-index: 2;
}

.scroll-header.hidden {
  transform: translateY(-100%);
}

.headroom-wrapper {
  height: 0px !important;
}
