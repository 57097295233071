.menuItem {
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
}

.menuCheckItem {
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 18px;
}

.menuCheckItem::after {
    content: "";
    width: 24px;
    height: 2px;
    background-color: #FDD535;
    position: absolute;
    bottom: 0;
    left: calc(50% - 12px);
}

/* :global(.ant-flex) {
    background-color: green;
    gap: 60px;
} */