:root {
  --doc-height: 100%;
}

@font-face {
  font-family: 'Harmonyos Sans';
  src: local('Harmonyos Sans'), url(./fonts/HARMONYOS_SANS_REGULAR.TTF) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Harmonyos Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181A1F;
}

body #root {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

::-webkit-scrollbar {
  background: transparent;
  width: 14px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  /* height: 56px; */
  border-radius: 8px;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #3E444D;
}

@media (max-width: 576px) {
  ::-webkit-scrollbar {
    width: 8px;
  }
}

/* @media (min-height: calc(100vh + 1px)) and (min-width: 576px) {
  body {
    overflow-y: scroll;
  }
} */

@media (min-width: 576px) {
  body {
    /* overflow-y: scroll !important;
    width: calc(100%) !important; */
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: calc(100% - 100vw);
    padding: 0 14px;
  }

  .appBarLocale .ant-dropdown-menu {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .appBarLocale .ant-dropdown-menu-item-active {
    background-color: #2b2514;
  }

  .appBarLocale .ant-dropdown-menu-item:hover {
    background-color: #2b2514 !important;
  }

  .appBarLocale .ant-dropdown-menu-title-content {
    text-align: center;
  }
}

@media (max-width: 576px) {
  body {
    margin-right: 0;
    padding: 0;
  }
}

/* @media (max-height: calc(100vh)) {
  body {
    overflow-y: scroll !important;
  }
} */

/* body::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 17px;
  background-color: transparent;
  pointer-events: none;
  z-index: 9999;
} */

/* @media (min-width: 576px) {
  body {
    overflow-y: scroll;
  }
} */

/* :hover::-webkit-scrollbar-thumb {
  background-color: #909090;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.topCard {
  position: fixed;
  top: -100px;
  left: 0;
  height: 164px;
  width: 100%;
  z-index: 2;
  background-color: #181A1F;
}
