.marketPlaceBox {
    position: relative;
}

.marketPlaceBox .topCard {
    position: fixed;
    top: -100px;
    left: 0;
    height: 164px;
    width: 100%;
    z-index: 2;
    background-color: #181A1F;
}

.marketPlaceBox .banner {
    width: 100%;
    height: 220px;
    padding: 24px;
    background: url('../../assets/common/banner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 6px;
}

.marketPlaceBox .ant-card {
    border-radius: 8px;
    border: 0.5px solid #2B3139;
    flex: 1;
    padding: 0 18px;
    height: 160px;
}

.marketPlaceBox .ant-tabs-tab {
    font-size: 17px;
    font-weight: 600;
}

.marketPlaceBox .ant-card-body {
    height: 100%;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.marketPlaceBox .ant-table.ant-table-small {
    border: none;
}

.marketPlaceBox .ant-table.ant-table-small .ant-table-tbody > tr > td {
    border: none;
}

.marketPlaceBox .ant-table-thead .ant-table-cell {
    background-color: transparent !important;
    font-weight: 700;
    font-size: 15px;
}

.marketPlaceBox .ant-table-thead >tr>th {
    border: none !important;
}

.marketPlaceBox .ant-table-thead >tr>th::before {
    height: 0px !important;
}

.marketPlaceBox .ant-table-thead .ant-table-cell .ant-table-filter-column {
    justify-content: flex-start;
}

.marketPlaceBox .ant-table-thead .ant-table-cell .ant-table-filter-column .ant-table-column-title {
    flex: unset;
}

.marketPlaceBox .ant-table-thead .ant-table-cell .ant-table-column-sorters {
    justify-content: flex-start;
}

.marketPlaceBox .ant-table-thead .ant-table-cell .ant-table-column-sorters .ant-table-column-title {
    flex: unset;
}

@media (max-width: 576px) {
    .marketPlaceBox .banner {
        height: 112px;
        padding: 10px;
        background: url('../../assets/common/bannerH5.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 6px;
    }
}