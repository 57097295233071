.splitModalBox .gasCard {
    height: 85px;
    width: 164px;
    border-radius: 6px;
    border: 1px solid #1C2025;
    background: #1C2025;
    cursor: pointer;
}

.splitModalBox .gasCard:hover {
    border: 1px solid #5D532C;
    background: #181A1F;
    box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
}

.splitModalBox .gasCard.check {
    border: 1px solid #5D532C;
    background: #181A1F;
    box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
}

.splitModalBox .gasInfoCard {
    border-radius: 8px;
    border: 1px solid #2B3139;
    background: #181A1F;
    height: 80px;
    padding: 27px 52px;
    width: calc(100% - 104px);
}

.splitModalBox .feeInput {
    width: 48px;
    height: 27px;
    text-align: end;
    padding-left: 4px;
    padding-right: 6px;
    font-size: 17px;
}

.splitModalBox .ant-slider-handle::after {
    background-color: #FDD535;
    box-shadow: 0 0 0 2px #FDD535 !important;
}

.splitModalBox .ant-spin-nested-loading {
    width: calc(100%);
}

.splitModalBox .broomList {
    border-radius: 8px;
    background: #101114;
    max-height: 120px;
    padding: 20px;
    width: calc(100% - 40px);
    overflow-y: auto;
}

.splitModalBox .broomListBox {
    overflow-y: auto;
}

.splitModalBox .broomListBox .ant-radio-wrapper .ant-radio + span {
    width: 100%;
}

@media (max-width: 576px) {
    .splitModalBox {
        padding: 16px;
    }
    .smSplitModal {
        width: 100vw !important;
        max-width: 100vw !important;
        top: 0;
        padding: 0 !important;
        margin: 0 !important;
    }
    
    .smSplitModal .ant-modal-content {
        height: calc(100vh);
        overflow-y: auto;
        border-radius: 0;
        padding: 0;
    }

    .splitModalBox .gasCard.check {
        border: 1px solid #5D532C;
        background: #181A1F;
        box-shadow: 0px 0px 40px 0px rgba(253, 213, 53, 0.06);
    }

    .splitModalBox .feeInput {
        width: 30px;
        height: 18px;
        padding-left: 4px;
        font-size: 8px;
    }

    .smSplitModal .gasCard {
        height: 82px;
        width: calc(33.3% - 35px);
    }
    .smSplitModal .gasInfoCard {
        border-radius: 4px;
        height: 72px;
        padding: 9px 8px;
        width: calc(100% - 16px);
    }

    .splitModalBox .broomList {
        max-height: 92px;
        padding: 8px;
        width: auto;
    }
}