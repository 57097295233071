
.listingBox .ant-select-selector {
    border-radius: 4px !important;
}

.listingBox .ant-progress-text {
    color: #4CC675;
}
.listingBox .ant-card {
    border-radius: 8px;
    border: 1px solid #2B3139;
    padding: 16px;
    height: 262px;
    width: 224px;
    flex: unset;
}

@media (any-hover: hover) {
    .listingBox .ant-card:hover {
        border-color: #FDD535;
    }
}

/* .listingBox .ant-card:last-child {
    margin-right: auto;
} */

.listingBox .ant-card-body {
    height: 100%;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.listingBox .ant-table.ant-table-small {
    border: none;
}

.listingBox .ant-table.ant-table-small .ant-table-tbody > tr > td {
    border: none;
}

.listingBox .ant-table-thead .ant-table-cell {
    background-color: transparent !important;
}

.listingBox .ant-table-thead >tr>th {
    border: none !important;
}

.listingBox .ant-table-thead >tr>th::before {
    height: 0px !important;
}

.listingBox .ant-table-thead .ant-table-cell .ant-table-filter-column {
    justify-content: flex-start;
}

.listingBox .ant-table-thead .ant-table-cell .ant-table-filter-column .ant-table-column-title {
    flex: unset;
}

.listingBox .ant-table-thead .ant-table-cell .ant-table-column-sorters {
    justify-content: flex-start;
}

.listingBox .ant-table-thead .ant-table-cell .ant-table-column-sorters .ant-table-column-title {
    flex: unset;
}

.listingBox .collect path {
    fill: #FDD535;
    opacity: 1;
}

.listingBox .listBox {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
    gap: 10px;
    justify-content: space-between;
}

.listingBox .ant-checkbox-inner {
    border-color: #63696c;
}

@media (max-width: 576px) {
    .listingBox .ant-card {
        padding: 8px;
        width: 100%;
        height: 190px;
        position: relative;
    }
    
    .listingBox .ant-checkbox {
        transform: scale(1.2);
    }

    .listingBox .myOrder .ant-card {
        padding: 16px 24px;
        width: 100%;
        height: 200px;
        background: #1C2025;
    }

    .listingBox .listBox {
        grid-template-columns: repeat(auto-fill, minmax(47.9%, 1fr));
    }
}
.listingBox .buyItemInfo {
    height: 82px;
    width: 100%;
    background: #FDD535;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    transition: transform 0.2s ease-in-out 0s;
    transform: translateY(-100%);
    z-index: 2;
}

.listingBox .buyItemInfo.hidden {
  transform: translateY(0);
}