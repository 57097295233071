.buyModalBox {
    padding: 17px 16px 0;
    width: calc(100% - 32px);
}

.buyModalBox .buyModalInfo {
    border-top: 0.5px solid #313131;
    width: 100%;
    padding-top: 17px;
}

.buyModalBox input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff;
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #101114;
}

.buyModalBox .ant-checkbox-inner {
    width: 24px;
    height: 24px;

    &::after {
        width: 8px;
        height: 12px;
    }
}

.buyModalBox .ant-checkbox {
    font-size: 24px;
}

.buyModalBox .gasInfoCard {
    border-radius: 8px;
    border: 1px solid #2B3139;
    background: rgba(16, 17, 20, 0.00);
    height: 149px;
    padding: 16px 14px 16px 21px;
    width: calc(100% - 35px);
    margin-bottom: 32px;
}

.buyModalBox label {
    width: 100%;
}

.buyModalBox .checkApprove label {
    width: auto;
}

.buyModalBox label {
    width: 100%;
}

.buyModalBox label::after {
    display: none;
}